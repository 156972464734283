import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import Text from 'shared/Text';
import { SUPPORTED_FILE_EXTENSIONS } from 'utils/constants';
import style from './FileDropZone.module.scss';

/**
 * Name: FileDropZone
 * Desc: Render file drop area for files
 * @param {func} onChange
 * @param {string} text
 * @param {string} description
 */

const FileDropZone = ({
  onChange = () => {},
  busy,
  supportedExt,
  accept,
  type,
  text,
  className,
}) => {
  const [isDrag, setIsDrag] = useState(false);
  const handleDropFile = useCallback((acceptedFiles) => {
    if (type === 'base64String') {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        onChange({ target: { files: acceptedFiles, base64String } });
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      onChange({ target: { files: acceptedFiles } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styledDiv = classnames(style.dropzone, {
    [style.busyDropzone]: busy || isDrag,
  });
  return (
    <Dropzone accept={accept} onDrop={handleDropFile}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section>
          {setIsDrag(isDragActive)}
          <div {...getRootProps()} className={`${styledDiv} ${className}`}>
            <input {...getInputProps()} />
            <div>
              <Text color="lightGrey" text={`${text} `} />
              <Text className={style.pointer} color="skyBlue" text="browse" />
              <Text color="lightGrey" text=" your library" />
            </div>
            <Text
              as="div"
              fontSize="xs"
              color="lightGrey"
              text={`(Supports ${
                supportedExt || SUPPORTED_FILE_EXTENSIONS.join(',')
              } file types only)`}
            />
            <Text
              as="div"
              fontSize="xs"
              color="lightGrey"
              text="(Maximum upload file size 2 GB)"
            />
          </div>
        </section>
      )}
    </Dropzone>
  );
};

FileDropZone.defaultProps = {
  accept: 'video/*',
  type: 'object',
  text: 'Drag your video here or',
  className: '',
};
FileDropZone.propTypes = {
  onChange: PropTypes.func,
  text: PropTypes.string,
  description: PropTypes.string,
  busy: PropTypes.oneOf(['string', 'bool']),
  supportedExt: PropTypes.array,
  accept: PropTypes.string,
  type: PropTypes.oneOf(['object', 'base64String']),
  className: PropTypes.string,
};

export default FileDropZone;
