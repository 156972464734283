import PropTypes from 'prop-types';
import React from 'react';
import AddCollectionModel from './AddCollectionModel';
import AddToCollection from './AddToCollection';
import AddVideoToCollection from './AddVideoToCollection';
import {
  useAddCollection,
  useAddToCollection,
  useAddVideoToCollection,
} from './CollectionUtils';

const Collection = ({
  isOpenAddCollectionModel,
  toggleModal,
  userInfo,
  history,
  isOpenAddToCollectionModel,
  toggleAddToCollectionModel,
  videoTitle,
  videoId,
  isOpenAddVideoToCollectionModel,
  toggleAddVideoToCollectionModel,
  collectionId,
  autoRefresh = false,
  onUpdateRecord = () => {},
}) => {
  // this is for add collection
  const {
    handleOnChange,
    title,
    handleValidation,
    description,
    errorMsg,
    handleAddCollection,
  } = useAddCollection(
    isOpenAddCollectionModel,
    toggleModal,
    userInfo,
    history,
    autoRefresh,
  );
  // this is for add to collection
  const {
    collectionsData,
    handleOnChangeCollection,
    searchTitle,
    collectionIds,
    handleAddToCollection,
    loader: collectionLoader,
  } = useAddToCollection({
    isOpenAddToCollectionModel,
    isOpenAddCollectionModel,
    toggleAddToCollectionModel,
    userInfo,
    videoId,
  });
  // this is for add video to collection
  const {
    videos,
    searchTitleVideo,
    selectedVideosIds,
    handleOnChangeVideos,
    handleAddVideoToCollection,
    loader,
  } = useAddVideoToCollection({
    isOpenAddVideoToCollectionModel,
    toggleAddVideoToCollectionModel,
    userInfo,
    collectionId,
    autoRefresh,
    onUpdateRecord,
  });

  return (
    <>
      <AddCollectionModel
        isOpen={isOpenAddCollectionModel}
        onCloseModel={toggleModal}
        onHandleChange={handleOnChange}
        title={title}
        onHandleValidation={handleValidation}
        description={description}
        errorMsg={errorMsg}
        onAddCollection={handleAddCollection}
      />
      <AddToCollection
        isOpen={isOpenAddToCollectionModel}
        onCloseModel={toggleAddToCollectionModel}
        onOpenAddCollectionModel={toggleModal}
        videoTitle={videoTitle}
        collectionsData={collectionsData}
        onHandleChange={handleOnChangeCollection}
        searchTitle={searchTitle}
        collectionIds={collectionIds}
        onAddToCollection={handleAddToCollection}
        loader={collectionLoader}
      />
      <AddVideoToCollection
        isOpen={isOpenAddVideoToCollectionModel}
        onCloseModel={toggleAddVideoToCollectionModel}
        videos={videos}
        searchTitle={searchTitleVideo}
        selectedVideosIds={selectedVideosIds}
        onHandleChange={handleOnChangeVideos}
        onAddVideoToCollection={handleAddVideoToCollection}
        loader={loader}
      />
    </>
  );
};
Collection.propTypes = {
  isOpenAddCollectionModel: PropTypes.bool,
  toggleModal: PropTypes.func,
  userInfo: PropTypes.object,
  history: PropTypes.object,
  isOpenAddToCollectionModel: PropTypes.bool,
  toggleAddToCollectionModel: PropTypes.func,
  videoTitle: PropTypes.string,
  videoId: PropTypes.string,
  isOpenAddVideoToCollectionModel: PropTypes.bool,
  toggleAddVideoToCollectionModel: PropTypes.func,
  collectionId: PropTypes.string,
  autoRefresh: PropTypes.bool,
  onUpdateRecord: PropTypes.func,
};
export default Collection;
