import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import style from './TextArea.module.scss';

/**
 * Name : TextArea
 * Desc : Render TextArea
 * @param {string} name
 * @param {any} id
 * @param {string} placeholder
 * @param {bool} disabled
 * @param {string} letterCount
 * @param {string} errorMessage
 * @param {string} className
 * @param {string} label
 * @param {string} variant
 * @param {string,bool} labelSizeLg
 */

const TextArea = ({
  letterCount,
  errorMessage,
  variant,
  className,
  labelSizeLg,
  label,
  inputRef = null,
  ...rest
}) => {
  const labelClasses = classNames({ [style.labelSizeLg]: labelSizeLg });
  const textAreaClasses = classNames(
    style.formControl,
    { [style[variant]]: variant },
    { [style.error]: errorMessage },
    { [style.letterCount]: letterCount },
    { [className]: className },
  );
  const reference = inputRef ? { ref: inputRef } : {};
  return (
    <>
      {label && (
        <label className={labelClasses} htmlFor={label}>
          {label}
        </label>
      )}
      <div className={style.textAreaWrapper}>
        <textarea
          aria-label={rest.name}
          className={textAreaClasses}
          {...rest}
          {...reference}
        />
        <div className={style.textAreaValidation}>
          {errorMessage && (
            <span className={style.errorMessage}>{errorMessage}</span>
          )}
          {letterCount && (
            <span className={style.letterCount}>{letterCount}</span>
          )}
        </div>
      </div>
    </>
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  letterCount: PropTypes.string,
  errorMessage: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  labelSizeLg: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
    PropTypes.object,
  ]),
  inputRef: PropTypes.object,
};

export default TextArea;
