import classNames from 'classnames';
import closeIcon from 'images/close-gray.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'reactstrap';
import Button from 'shared/Button';
import Dialog from 'shared/Dialog';
import Heading from 'shared/Heading';
import Image from 'shared/Image';
import Inputs from 'shared/Inputs';
import Loader from 'shared/Loader';
import ScrollBar from 'shared/ScrollBar';
import Text from 'shared/Text';
import { formateDate } from 'utils/utils';
import style from './AddToCollection.module.scss';

const AddToCollection = ({
  isOpen,
  onCloseModel,
  searchTitle,
  onHandleChange,
  onHandleValidation,
  onOpenAddCollectionModel,
  collectionsData,
  collectionIds,
  onAddToCollection,
  loader,
}) => {
  return (
    <Dialog
      hasOpen={isOpen}
      backdrop="static"
      hasHeader={false}
      className={style.collectionListModalWrapper}
    >
      <div className={style.modalHeader}>
        <Text
          className={style.modalHeaderTitle}
          fontFamily="semiBold"
          fontSize="fsxl"
          text={`Add This Video to a Collection`}
        />
        <Button className={style.closeIcon} onClick={onCloseModel}>
          <Image name="profile" src={closeIcon} />
        </Button>
      </div>
      <div className={style.modalBody}>
        {loader && <Loader />}
        <FormGroup className="mb-0">
          <Inputs
            type="text"
            labelSizeLg
            placeholder="Type to search for collections by title"
            name="searchTitle"
            value={searchTitle}
            onChange={onHandleChange}
            onBlur={onHandleValidation}
            className={style.collectionSearchInput}
          />
        </FormGroup>
        <div className={style.collectionList}>
          <ScrollBar className={style.customScroll}>
            {collectionsData.map(
              ({
                _id,
                title,
                description,
                created_at: createdAt,
                videoCount,
              }) => (
                <div
                  className={classNames(style.collection, {
                    [style.select]: collectionIds.find((ids) => ids === _id),
                  })}
                  key={_id}
                >
                  <div>
                    <Inputs
                      type="checkbox"
                      id={`checkbox_${_id}`}
                      space="m0"
                      value={_id}
                      onChange={onHandleChange}
                      name={`checkbox_${_id}`}
                    />
                  </div>
                  <div className={style.videoCount}>
                    <div className={style.firstLayer}></div>
                    <div className={style.secondLayer}></div>
                    <div className={style.count}>
                      <Text text={videoCount} color="white" />
                    </div>
                  </div>
                  <div className={style.collectionDetail}>
                    <Heading
                      text={title}
                      type="h5"
                      className={style.collectionName}
                    />
                    <Text
                      text={description}
                      color="lightGrey"
                      fontSize="xs"
                      as="div"
                      className={style.description}
                    />
                    <Text
                      text={`created ${formateDate(createdAt)} `}
                      color="lightGrey"
                      fontSize="xs"
                    />
                  </div>
                </div>
              ),
            )}
          </ScrollBar>
        </div>
        <div className={style.modelActionBtn}>
          <Button
            variant="outline-primary"
            text="Create a new collection"
            onClick={onOpenAddCollectionModel}
            className={style.createCollectionBtn}
          />
          <Button
            variant="primary"
            text="Add"
            onClick={onAddToCollection}
            className={style.addBtn}
            disabled={!collectionIds.length || loader}
          />
        </div>
      </div>
    </Dialog>
  );
};
AddToCollection.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModel: PropTypes.func,
  searchTitle: PropTypes.string,
  onHandleChange: PropTypes.func,
  onHandleValidation: PropTypes.func,
  onOpenAddCollectionModel: PropTypes.func,
  videoTitle: PropTypes.string,
  collectionsData: PropTypes.array,
  collectionIds: PropTypes.array,
  onAddToCollection: PropTypes.func,
  loader: PropTypes.bool,
};
export default AddToCollection;
