import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'shared/Icon';
import Loader from 'shared/Loader';
import style from './Button.module.scss';

/**
 *Name: Button
 *Desc: Render button
 * @param {string} text
 * @param {string} icon
 * @param {func} onClick
 * @param {string} variant
 * @param {bool} link
 * @param {bool} buttonIcon
 * @param {string} className
 * @param {string} iconDataTest
 * @param {bool} keepOpacity
 * @param {object} buttonRef
 * @param {object,node,string} children
 * @param {bool} textDecoration
 */

const Button = (props) => {
  const {
    className,
    variant,
    iconDataTest,
    size,
    keepOpacity,
    buttonRef,
    children,
    textDecoration,
    loader,
    ...rest
  } = props;

  const classes = classNames(
    style.btn,
    { [style[variant]]: variant },
    { [style.keepOpacityOnDisabled]: keepOpacity },
    { [style[size]]: size },
    { [`${style.textDecoration}`]: textDecoration },
    { [className]: className },
  );

  let spanProps = {};
  if (props.icon && !props.text) {
    spanProps = { ...rest };
    delete spanProps.onClick;
  }
  const refObj = buttonRef ? { ref: buttonRef } : {};
  return (
    <button {...rest} {...refObj} className={classes}>
      {props.icon && (
        <Icon {...spanProps} variant={props.icon} data-test={iconDataTest} />
      )}
      {props.text}
      {children}
      {loader && (
        <div className={style.loaderButton}>
          <Loader size="sm" />
        </div>
      )}
    </button>
  );
};

Button.defaultProps = {
  text: '',
  textDecoration: false,
  type: 'button',
};

// Props Validation
Button.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconDataTest: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  icon: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.bool,
  light: PropTypes.bool,
  size: PropTypes.string,
  buttonIcon: PropTypes.bool,
  variant: PropTypes.string,
  buttonRef: PropTypes.object,
  keepOpacity: PropTypes.bool,
  loader: PropTypes.bool,
  textDecoration: PropTypes.bool,
};

export default Button;
