import PropTypes from 'prop-types';
import React from 'react';

/**
 * Name: Footer
 * Desc: Render footer
 * @param {string} copyrightText
 * @param {node} children
 */

const Footer = ({ copyrightText, children }) => {
  return (
    <footer className="footer mt-auto py-3">
      <div className="container">
        <div className="copyright">
          {children || copyrightText}
          {/* {copyrightText
            ? copyrightText
            : `Skoodio Copyright@${new Date().getFullYear()}`} */}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
  copyrightText: PropTypes.string,
};

export default Footer;
