import PropTypes from 'prop-types';
import React from 'react';
import TextInput from './TextInput';
import TextArea from './TextArea';
import CheckBox from './CheckBox';
/**
 * Name : Inputs
 * Desc : Render Inputs
 * @param {string} type
 */

const Inputs = ({ type, ...rest }) => {
  return(
    <>
    {type === 'text' && (
      <TextInput type={type} {...rest} />
    )}
    {type === 'password' && (
      <TextInput type={type} {...rest} />
    )}
    {type === 'textarea' && (
      <TextArea type={type} {...rest} />
    )}
    {type === 'checkbox' && (
      <CheckBox type={type} {...rest} />
    )}
    </>
  )
};

Inputs.defaultProps = { type: 'text' };
Inputs.propTypes = { type: PropTypes.string };

export default Inputs;
