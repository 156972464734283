import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Image from 'shared/Image';
import Text from 'shared/Text';
import { getFirstChar } from 'utils/utils';
import Style from './MediaImage.module.scss';

/**
 * Name: MediaImage
 * Desc: Render MediaImage
 * @param {string} height
 * @param {string} width
 * @param {string} imagePath
 * @param {string} className
 */
const MediaImage = ({
  height,
  width,
  imagePath = '',
  className,
  fontSize,
  imageSize,
  profileColor,
  firstName = '',
  lastName = '',
  thumbText = '',
}) => {
  const mediaImageClasses = classNames(Style.mediaImageWrapper, {
    [className]: className,
    [Style.defaultImage]: !imagePath,
    [Style[profileColor]]: !imagePath,
    [Style[imageSize]]: !!imageSize,
  });

  return (
    <div name="title" className={mediaImageClasses}>
      {imagePath ? (
        <Image
          src={imagePath}
          height={height}
          width={width}
          alt="profile"
          name="profile"
        />
      ) : (
        <Text
          fontSize={fontSize}
          color="white"
          text={thumbText || getFirstChar(firstName, lastName)}
          as="div"
          fontFamily="semiBold"
          className={Style.userText}
        />
      )}
    </div>
  );
};

MediaImage.defaultProps = {
  height: '98px',
  width: '98px',
  fontSize: 'lg',
  profileColor: 'dotBg',
  thumbText: '',
};
// Props Validation
MediaImage.propTypes = {
  height: PropTypes.string,
  profileColor: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  imagePath: PropTypes.string,
  className: PropTypes.string,
  imageSize: PropTypes.string,
  thumbText: PropTypes.string,
};

export default MediaImage;
