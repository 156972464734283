import classnames from 'classnames';
import arrowLeftIcon from 'images/arrow-left-icon.svg';
import arrowRightIcon from 'images/arrow-right-icon.svg';
import iconsNavAddButton from 'images/icons-nav-add-button.svg';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'shared/Button';
import Collection from 'shared/Collection';
import useUpdateCount from 'shared/customHooks/useUpdateCount';
import AddVideoLibrary from 'shared/Header/AddVideoLibrary';
import Heading from 'shared/Heading';
import Image from 'shared/Image';
import Text from 'shared/Text';
// import { isMobile } from 'utils/utils';
import { setLocalStorage } from 'utils/utils';
import style from './SideNavBar.module.scss';
import { reviewMenuItems, workMenuItems } from './SideNavBarConstant';

const SideNavBar = ({ location, history = {} }) => {
  const [hoverImageId, setHoverImageId] = useState();
  // const [showCollapsedBtn, setShowCollapsedBtn] = useState(!!isMobile());
  const [isOpenAddVideoModal, setIsOpenAddVideoModal] = useState(false);
  const [isAddCollectionModel, setIsAddCollectionModel] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const sidebarData = useSelector((state) => state.sidebarData);
  const { user_id: userId } = userInfo;
  const { pathname } = location;
  // const [width,setWidth]= useState(window.innerWidth)

  const defaultToggValue = true;
  const sidebarCollapsed = JSON.parse(
    localStorage.getItem('toggleElementValue'),
  );
  const [isOpen, setIsOpen] = useState(
    sidebarCollapsed ? !defaultToggValue : defaultToggValue,
  );

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
      window.localStorage.setItem('toggleElementValue', defaultToggValue);
    } else {
      setIsOpen(true);
      localStorage.removeItem('toggleElementValue');
    }
  };

  const getActiveClass = (to) => {
    return to === pathname;
  };
  const handleAddVideoModal = () =>
    setIsOpenAddVideoModal(!isOpenAddVideoModal);

  const handleAddCollectionModal = () =>
    setIsAddCollectionModel(!isAddCollectionModel);

  const reqParams = useRef({
    reqParam: {
      ownerUserId: userId,
      sort: { created_at: -1 },
    },
  });
  const collectionReqParams = useRef({
    reqParam: {
      ownerUserId: userId,
      sort: { created_at: -1 },
    },
  });
  useUpdateCount(reqParams.current, collectionReqParams.current);

  const getButton = () => {
    return (
      <Button
        variant="link"
        onClick={toggle}
        className={style.toggleSideBarBtn}
      >
        {isOpen ? (
          <Image
            height="18px"
            width="21px"
            name="profile"
            src={arrowRightIcon}
            // variant="grayButton"
            className="mr-1"
          />
        ) : (
          <Image
            height="18px"
            width="21px"
            name="profile"
            src={arrowLeftIcon}
            // variant="grayButton"
            className="mr-1"
          />
        )}
      </Button>
    );
  };
  const getLinksAndImages = (
    id,
    to,
    name,
    icon,
    hoverIcon,
    isAddVideo,
    isAddCollection,
  ) => {
    const isActive = getActiveClass(to);
    const setCurrentPage = (pageName, URL) => {
      setLocalStorage('currentPage', { pageName, URL });
    };
    return (
      <Link
        className={style.textColor}
        key={name}
        to={to}
        name={name}
        size="xlg"
        color="lightGrey"
        onClick={() => setCurrentPage(name, to)}
      >
        {icon && (
          <Image
            name={name}
            src={hoverImageId === id || isActive ? hoverIcon : icon}
            className={classnames(style.menuImage, {
              [style.showImage]: !isOpen,
            })}
          />
        )}
        <Text
          text={name}
          className={classnames(style.linkText, {
            [style.textToggle]: !isOpen,
          })}
        />
        {isAddVideo && (
          <Text
            text={`(${sidebarData.videos || 0})`}
            className={classnames(style.linkText, {
              [style.textToggle]: !isOpen,
            })}
          />
        )}
        {isAddCollection && (
          <Text
            text={`(${sidebarData.collections || 0})`}
            className={classnames(style.linkText, {
              [style.textToggle]: !isOpen,
            })}
          />
        )}
      </Link>
    );
  };
  const SideBarStyle = classnames(style.SideNavBarWrapper, {
    [style.show]: isOpen,
  });

  return (
    <div
      className={classnames(style.sidebarWrapper, {
        [style.collapse]: !isOpen,
      })}
    >
      <div
        className={SideBarStyle}
        // onMouseEnter={() => setShowCollapsedBtn(!showCollapsedBtn)}
        // onMouseLeave={() =>
        //   isMobile() ? setShowCollapsedBtn(true) : setShowCollapsedBtn(false)
        // }
      >
        <div className={style.workMenu}>
          <div className={style.toggleBtn}>
            <Heading
              text="My Work"
              className={classnames(
                style.myWorkButton,
                style.customMyWorkButton,
                {
                  [style.textToggle]: !isOpen,
                },
              )}
              type="h4"
            />
            {getButton()}
          </div>
          {workMenuItems.map(
            ({
              id,
              to,
              name,
              icon,
              isAddVideo,
              hoverIcon,
              isAddCollection,
            }) => {
              const isActive = getActiveClass(to);
              return (
                <div className={style.textColor} key={name}>
                  <div
                    className={classnames(style.menuLinks, {
                      [style.active]: isActive,
                    })}
                    onMouseEnter={() => setHoverImageId(id)}
                    onMouseLeave={() => setHoverImageId('')}
                  >
                    {getLinksAndImages(
                      id,
                      to,
                      name,
                      icon,
                      hoverIcon,
                      isAddVideo,
                      isAddCollection,
                    )}
                  </div>
                  {!!isAddVideo && (
                    <div
                      className={classnames({ [style.textToggle]: !isOpen })}
                    >
                      <Button variant="link" onClick={handleAddVideoModal}>
                        <Image name="add" src={iconsNavAddButton} />
                      </Button>
                    </div>
                  )}
                  {!!isAddCollection && (
                    <div
                      className={classnames({ [style.textToggle]: !isOpen })}
                    >
                      <Button variant="link" onClick={handleAddCollectionModal}>
                        <Image name="add" src={iconsNavAddButton} />
                      </Button>
                    </div>
                  )}
                </div>
              );
            },
          )}
        </div>
        {/* TODO TODO {type === VIDEO_FILTER_PAGE.MY_VIDEO && showDelete && (
          <Button
            variant="link"
            type="button"
            text="Delete"
            className={style.deleteLink}
            onClick={onDelete}
          />
        )} */}
        <div className={style.reviewMenus}>
          <Heading
            text="Reviews"
            className={classnames(style.myWorkButton, {
              [style.textToggle]: !isOpen,
            })}
            type="h4"
          />
          {reviewMenuItems.map(({ id, to, name, icon, hoverIcon }) => {
            const isActive = getActiveClass(to);

            return (
              <div className={style.textColor} key={name}>
                <div
                  className={classnames(style.menuLinks, {
                    [style.active]: isActive,
                  })}
                  onMouseEnter={() => setHoverImageId(id)}
                  onMouseLeave={() => setHoverImageId('')}
                >
                  {getLinksAndImages(id, to, name, icon, hoverIcon)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <AddVideoLibrary
        isOpenAddVideoModal={isOpenAddVideoModal}
        toggleModal={handleAddVideoModal}
        userInfo={userInfo}
        history={history}
      />
      <Collection
        isOpenAddCollectionModel={isAddCollectionModel}
        toggleModal={handleAddCollectionModal}
        userInfo={userInfo}
        history={history}
        autoRefresh
      />
    </div>
  );
};
SideNavBar.propTypes = {
  type: PropTypes.oneOf([
    'myVideo',
    'public',
    'shared',
    'myReviews',
    'mySaved',
  ]),
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  location: PropTypes.object,
};
export default SideNavBar;
