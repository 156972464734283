import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import Layout from 'shared/Layouts';
import { PRIVATE_ROUTES } from './appRoutes';
import AuthRoute from './AuthRoute';

const PrivateRoute = (props) => {
  const { loggedIn, userInfo } = useSelector((state) => state.auth);
  return (
    <>
      <Switch>
        {PRIVATE_ROUTES.map((route) => {
          return (
            <AuthRoute
              key={route.pageTitle}
              id={route.key}
              isAuthenticated={loggedIn}
              layout={Layout}
              history={props.history}
              session={userInfo}
              {...route}
            />
          );
        })}
      </Switch>
    </>
  );
};
export default PrivateRoute;
