import PropTypes from 'prop-types';
import React from 'react';
import AddVideoLibraryModal from 'shared/AddVideoLibraryModal';
import { useAddVideo } from './AddVideoLibraryUtils';

const AddVideoLibrary = ({
  isOpenAddVideoModal,
  toggleModal,
  userInfo,
  history,
}) => {
  const {
    exceedFileSize,
    title,
    errorMsg,
    description,
    fileName,
    fileSize,
    uploadStatus,
    progressCount,
    handleAddVideo,
    handleFileChange,
    handleDeleteFile,
    handleChange,
    handleValidation,
  } = useAddVideo(userInfo, history, toggleModal, isOpenAddVideoModal);
  return (
    <AddVideoLibraryModal
      title={title}
      description={description}
      onAddVideo={handleAddVideo}
      progressCount={progressCount}
      isOpen={isOpenAddVideoModal}
      onCloseModal={toggleModal}
      onFileChange={handleFileChange}
      fileName={fileName}
      fileSize={fileSize}
      uploadStatus={uploadStatus}
      onFileDelete={handleDeleteFile}
      onHandleChange={handleChange}
      errorMsg={errorMsg}
      handleValidation={handleValidation}
      exceedFileSize={exceedFileSize}
    />
  );
};

AddVideoLibrary.propTypes = {
  isOpenAddVideoModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  userInfo: PropTypes.object,
  history: PropTypes.object,
};
export default AddVideoLibrary;
