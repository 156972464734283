import notificationBell from 'assets/images/notification-bell.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { NOTIFICATIONS_ALL } from 'routes/appRoutes';
import Image from 'shared/Image';
import Text from 'shared/Text';
import style from './HeaderNotificationBell.module.scss';
import { useHeaderNotificationBell } from './HeaderNotificationBellUtils';

/**
 * Name: HeaderNotificationBell
 * Desc: Render NotificationBell
 * @param {object} userInfo
 * @param {object} history
 */
const HeaderNotificationBell = ({ userInfo, history }) => {
  const { notificationCount, handleOnClick } = useHeaderNotificationBell(
    userInfo,
    history,
  );
  return (
    <>
      <Link
        to={NOTIFICATIONS_ALL}
        className={style.notificationCounter}
        title="Notification"
        onClick={() => handleOnClick()}
      >
        <Image
          name="notificationBell"
          src={notificationBell}
          width="31px"
          height="24px"
        />
        {notificationCount ? (
          <Text fontSize="xxs" text={notificationCount} color="white" />
        ) : (
          ''
        )}
      </Link>
    </>
  );
};
HeaderNotificationBell.propTypes = {
  history: PropTypes.object,
  userInfo: PropTypes.object,
};

export default HeaderNotificationBell;
