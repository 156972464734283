import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { COMMON_ROUTE_KEYS, HOME_PAGE_PATH } from 'routes/appRoutes';

const NoAuthRoute = ({
  component: Component,
  isAuthenticated,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && !COMMON_ROUTE_KEYS.includes(rest.id)) {
          return (
            <Redirect
              to={
                props.location.state
                  ? props.location.state.from
                  : HOME_PAGE_PATH
              }
            />
          );
        }

        return (
          <Suspense fallback={<></>}>
            <Component
              nextPage={rest.nextPage}
              prevPage={rest.prevPage}
              pageTitle={rest.pageTitle}
              isAuthenticated={isAuthenticated}
              {...props}
            />
          </Suspense>
        );
      }}
    />
  );
};

NoAuthRoute.propTypes = {
  component: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  layout: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default NoAuthRoute;
