import classNames from 'classnames';
import closeIcon from 'images/close-gray.svg';
import video from 'images/video.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'reactstrap';
import Button from 'shared/Button';
import Dialog from 'shared/Dialog';
import Heading from 'shared/Heading';
import Image from 'shared/Image';
import Inputs from 'shared/Inputs';
import Loader from 'shared/Loader';
import ScrollBar from 'shared/ScrollBar';
import Text from 'shared/Text';
import { formateDate, getVideoThumbUrl } from 'utils/utils';
import style from './AddVideoToCollection.module.scss';

const AddVideoToCollection = ({
  isOpen,
  onCloseModel,
  searchTitle,
  onHandleChange,
  videos,
  selectedVideosIds,
  onAddVideoToCollection,
  loader,
}) => {
  return (
    <Dialog
      hasOpen={isOpen}
      backdrop="static"
      hasHeader={false}
      className={style.collectionListModalWrapper}
    >
      <div className={style.modalHeader}>
        <Text
          className={style.modalHeaderTitle}
          fontFamily="semiBold"
          fontSize="fsxl"
          text={`Add Video/s to this Collection`}
        />
        <Button className={style.closeIcon} onClick={onCloseModel}>
          <Image name="profile" src={closeIcon} />
        </Button>
      </div>
      <div className={style.modalBody}>
        {loader && <Loader />}
        <FormGroup className="mb-0">
          <Inputs
            type="text"
            labelSizeLg
            placeholder="Type to search video by title"
            name="searchTitle"
            value={searchTitle}
            onChange={onHandleChange}
            className={style.collectionSearchInput}
          />
        </FormGroup>
        <div className={style.videosList}>
          <ScrollBar className={style.customScroll}>
            {videos.map(
              ({
                _id,
                title,
                description,
                created_at: createdAt,
                thumbnail_image: thumbnailImage,
              }) => (
                <div
                  className={classNames(style.videos, {
                    [style.select]: selectedVideosIds.find(
                      (ids) => ids === _id,
                    ),
                  })}
                  key={_id}
                >
                  <div>
                    <Inputs
                      type="checkbox"
                      id={`checkbox_${_id}`}
                      space="m0"
                      value={_id}
                      onChange={onHandleChange}
                      name={`checkbox_${_id}`}
                    />
                  </div>
                  <div className={style.videoImageWrapper}>
                    <div className={style.videoImage}>
                      <Image
                        src={getVideoThumbUrl(thumbnailImage)}
                        name={thumbnailImage}
                        className={style.thumbImage}
                      />
                      <Image
                        name="video"
                        src={video}
                        className={style.videoButton}
                      />
                    </div>
                  </div>
                  <div className={style.videoDetail}>
                    <Heading
                      text={title}
                      type="h5"
                      className={style.videoName}
                    />
                    <Text
                      text={description}
                      color="lightGrey"
                      fontSize="xs"
                      as="div"
                      className={style.description}
                    />
                    <Text
                      text={`created ${formateDate(createdAt)} `}
                      color="lightGrey"
                      fontSize="xs"
                    />
                  </div>
                </div>
              ),
            )}
          </ScrollBar>
        </div>
        <div className={style.modelActionBtn}>
          <Button
            variant="primary"
            text="Add"
            onClick={onAddVideoToCollection}
            className={style.addBtn}
            disabled={!selectedVideosIds.length || loader}
          />
        </div>
      </div>
    </Dialog>
  );
};
AddVideoToCollection.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModel: PropTypes.func,
  searchTitle: PropTypes.string,
  onHandleChange: PropTypes.func,
  onAddVideoToCollection: PropTypes.func,
  videos: PropTypes.array,
  selectedVideosIds: PropTypes.array,
  handleOnChangeVideos: PropTypes.func,
  loader: PropTypes.bool,
};
export default AddVideoToCollection;
