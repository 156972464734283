import { lazy } from 'react';

const Registration = lazy(() => import('app/pages/Registration'));
const Login = lazy(() => import('app/pages/Login'));
const Home = lazy(() => import('app/pages/Home'));
const PostLogin = lazy(() => import('app/pages/PostLogin'));
const Student = lazy(() => import('app/pages/Student'));
const Coaches = lazy(() => import('app/pages/Coaches'));
const NotFound = lazy(() => import('app/pages/NotFound'));
const ForgotPassword = lazy(() => import('app/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('app/pages/ResetPassword'));
const PostInvite = lazy(() => import('app/pages/PostInvite'));
const VerifyAccount = lazy(() => import('app/pages/VerifyAccount'));
const EmailVerified = lazy(() => import('app/pages/EmailVerified'));
const MyVideos = lazy(() => import('app/pages/MyVideos'));
const SharedWithMe = lazy(() => import('app/pages/SharedWithMe'));
const MyReviews = lazy(() => import('app/pages/MyReviews'));
const MySaved = lazy(() => import('app/pages/MySaved'));
const Splash = lazy(() => import('app/pages/Splash'));
const Notifications = lazy(() => import('app/pages/Notifications'));
const FollowUser = lazy(() => import('app/pages/FollowUser'));
const SignUpUser = lazy(() => import('app/pages/SignUp'));

const LockedReview = lazy(() => import('app/pages/LockedReview'));
const UnpublishedMessage = lazy(() => import('app/pages/UnpublishedMessage'));
const DeletedVideoMessage = lazy(() => import('app/pages/DeletedVideoMessage'));
const Feedback = lazy(() => import('app/pages/Feedback'));

const UserProfile = lazy(() => import('app/pages/UserProfile'));

const Profile = lazy(() => import('app/pages/Profile'));
const EmailAndPassword = lazy(() => import('app/pages/UpdateAccountDetails'));
const Settings = lazy(() => import('app/pages/Settings'));
const AdminUserList = lazy(() => import('app/pages/AdminUserList'));
const MyCollection = lazy(() => import('app/pages/MyCollection'));
const All = lazy(() => import('app/pages/All'));

export const ADMIN_USER_LIST_PATH = '/admin/users';

export const USER_PROFILE_PAGE = '/user-profile';
export const SIGN_UP_USER_PATH = '/confirmation-registration/:passCode';

export const USER_PROFILE_PATH = '/user-profile/:userId';

export const FOLLOW_USERS_PATH = '/users/:followId';

export const HOME_PAGE_PATH = '/home';
export const MY_VIDEOS_PAGE_PATH = '/my-videos';
export const SHARED_WITH_ME_PAGE_PATH = '/shared-with-me';
export const MY_REVIEWS_PAGE_PATH = '/my-reviews';
export const MY_SAVED_PAGE_PATH = '/my-saved';
export const FEEDBACK_PAGE_PATH = '/:videoId';
export const FEEDBACK_PAGE_PATH_WITH_COLLECTION = '/:videoId/:collectionId';

export const MY_COLLECTION_PAGE_PATH = '/my-collection';

export const ALL_PAGE_PATH = '/all';

export const REGISTRATION_PAGE_PATH = '/registration';

export const LOGIN_PAGE_PATH = '/login';
export const POST_LOGIN_PAGE_PATH = '/post-login';
export const STUDENT_PAGE_PATH = '/student';
export const FEEDBACK_OLD_PAGE_PATH = '/feedback/:assignmentId';
export const FORGOT_PASSWORD_PAGE_PATH = '/forgotpassword';
export const RESET_PASSWORD_PAGE_PATH = '/reset-password/:resetCode';
export const NOTIFICATIONS_ALL = '/notification/all';
export const POST_INVITE_PAGE_PATH =
  '/accepting-shared-review/:invitationToken';
export const ACCOUNT_VERIFICATION_PATH =
  '/account-verification/:verificationCode/:userId';
export const EMAIL_VERIFIED_PAGE_PATH = '/EmailVerifiedPage';

export const SPLASH_PAGE_PATH = '/';
export const NOTIFICATIONS_PAGE_PATH = '/notification/:typeId';
export const LOCKED_REVIEW_PAGE_PATH = '/locked-review';
export const UNPUBLISH_MESSAGE_PAGE_PATH = '/unpublished-message';
export const DELETED_VIDEO_MESSAGE_PAGE_PATH = '/deleted-video-message';

export const PROFILE_PAGE_PATH = '/my-account';
export const EMAIL_AND_PASSWORD_PAGE_PATH = '/update-account-details';
export const SETTINGS_PAGE_PATH = '/settings';

export const ERROR_ROUTES = '*';

export const PUBLIC_ROUTES = [
  {
    key: 'SplashPage',
    path: SPLASH_PAGE_PATH,
    pageTitle: 'Splash Page',
    component: Splash,
    subRoutes: [],
    nextPage: REGISTRATION_PAGE_PATH,
    prevPage: null,
    exact: true,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'LoginPage',
    path: LOGIN_PAGE_PATH,
    pageTitle: 'Login Page',
    component: Login,
    subRoutes: [],
    nextPage: HOME_PAGE_PATH,
    prevPage: REGISTRATION_PAGE_PATH,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'RegistrationPage',
    path: REGISTRATION_PAGE_PATH,
    pageTitle: 'Registration Page',
    component: Registration,
    subRoutes: [],
    nextPage: SIGN_UP_USER_PATH,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'SignUpPage',
    path: SIGN_UP_USER_PATH,
    pageTitle: 'Direct sign up Page',
    component: SignUpUser,
    subRoutes: [],
    nextPage: LOGIN_PAGE_PATH,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'ForgotPasswordPage',
    path: FORGOT_PASSWORD_PAGE_PATH,
    pageTitle: 'ForgotPassword Page',
    component: ForgotPassword,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'LockedReviewPage',
    path: LOCKED_REVIEW_PAGE_PATH,
    pageTitle: 'Splash Page',
    component: LockedReview,
    subRoutes: [],
    nextPage: { LOGIN_PAGE_PATH, REGISTRATION_PAGE_PATH },

    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'UnpublishedMessagePage',
    path: UNPUBLISH_MESSAGE_PAGE_PATH,
    pageTitle: 'Unpublished Message Page',
    component: UnpublishedMessage,
    subRoutes: [],
    nextPage: { LOGIN_PAGE_PATH, REGISTRATION_PAGE_PATH },

    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'DeletedVideoMessagePage',
    path: DELETED_VIDEO_MESSAGE_PAGE_PATH,
    pageTitle: 'Deleted Video Message Page',
    component: DeletedVideoMessage,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'VerifyAccountPage',
    path: ACCOUNT_VERIFICATION_PATH,
    pageTitle: 'Verify Account Page',
    component: VerifyAccount,
    subRoutes: [],
    nextPage: LOGIN_PAGE_PATH,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },

  {
    key: 'EmailVerifiedPage',
    path: EMAIL_VERIFIED_PAGE_PATH,
    pageTitle: 'EmailVerified Page',
    component: EmailVerified,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'ResetPasswordPage',
    path: RESET_PASSWORD_PAGE_PATH,
    pageTitle: 'ResetPassword Page',
    component: ResetPassword,
    subRoutes: [],
    nextPage: HOME_PAGE_PATH,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'PostInvitePage',
    path: POST_INVITE_PAGE_PATH,
    pageTitle: 'PostInvite Page',
    component: PostInvite,
    subRoutes: [],
    nextPage: {
      HOME_PAGE_PATH,
      SHARED_WITH_ME_PAGE_PATH,
    },
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'FeedbackPage',
    path: FEEDBACK_PAGE_PATH_WITH_COLLECTION,
    pageTitle: 'Feedback Page',
    component: Feedback,
    subRoutes: [],
    nextPage: {
      SHARED_WITH_ME_PAGE_PATH,
      LOCKED_REVIEW_PAGE_PATH,
      UNPUBLISH_MESSAGE_PAGE_PATH,
      DELETED_VIDEO_MESSAGE_PAGE_PATH,
    },
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'FeedbackPage',
    path: FEEDBACK_PAGE_PATH,
    pageTitle: 'Feedback Page',
    component: Feedback,
    subRoutes: [],
    nextPage: {
      SHARED_WITH_ME_PAGE_PATH,
      LOCKED_REVIEW_PAGE_PATH,
      UNPUBLISH_MESSAGE_PAGE_PATH,
      DELETED_VIDEO_MESSAGE_PAGE_PATH,
    },
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
  {
    // SHOULD BE IN LAST INDEX
    key: 'NotfoundPage',
    path: ERROR_ROUTES,
    pageTitle: 'Notfound Page',
    component: NotFound,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
];

export const PRIVATE_ROUTES = [
  {
    key: 'HomePage',
    path: HOME_PAGE_PATH,
    pageTitle: 'Home Page',
    component: Home,
    subRoutes: [],
    nextPage: STUDENT_PAGE_PATH,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'postLoginPage',
    path: POST_LOGIN_PAGE_PATH,
    pageTitle: 'Post Login Page',
    component: PostLogin,
    subRoutes: [],
    nextPage: STUDENT_PAGE_PATH,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'All',
    path: ALL_PAGE_PATH,
    pageTitle: 'My Collection Page',
    component: All,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'MyCollection',
    path: MY_COLLECTION_PAGE_PATH,
    pageTitle: 'My Collection Page',
    component: MyCollection,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'MyVideoPage',
    path: MY_VIDEOS_PAGE_PATH,
    pageTitle: 'My Videos Page',
    component: MyVideos,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'SharedWithMePage',
    path: SHARED_WITH_ME_PAGE_PATH,
    pageTitle: 'Shared with me Page',
    component: SharedWithMe,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },

  {
    key: 'UserProfilePage',
    path: USER_PROFILE_PATH,
    pageTitle: 'user profile page',
    component: UserProfile,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'FollowUsers',
    path: FOLLOW_USERS_PATH,
    pageTitle: 'Follow userS Page',
    component: FollowUser,
    subRoutes: [],
    nextPage: USER_PROFILE_PATH,
    prevPage: null,
    exact: true,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },

  {
    key: 'MyReviewsPage',
    path: MY_REVIEWS_PAGE_PATH,
    pageTitle: 'My reviews Page',
    component: MyReviews,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'MySavedPage',
    path: MY_SAVED_PAGE_PATH,
    pageTitle: 'My reviews Page',
    component: MySaved,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },

  {
    key: 'StudentPage',
    path: STUDENT_PAGE_PATH,
    pageTitle: 'Student Page',
    component: Student,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'FeedbackOldPage',
    path: FEEDBACK_OLD_PAGE_PATH,
    pageTitle: 'Feedback Page',
    component: Coaches,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: false,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'MyNotificationPage',
    path: NOTIFICATIONS_PAGE_PATH,
    pageTitle: 'My Notification Page',
    component: Notifications,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'MyProfilePage',
    path: PROFILE_PAGE_PATH,
    pageTitle: 'My Profile Page',
    component: Profile,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'MyEmailAndPasswordPage',
    path: EMAIL_AND_PASSWORD_PAGE_PATH,
    pageTitle: 'My Email and password Page',
    component: EmailAndPassword,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'MySettingsPage',
    path: SETTINGS_PAGE_PATH,
    pageTitle: 'My Settings Page',
    component: Settings,
    subRoutes: [],
    nextPage: SPLASH_PAGE_PATH,
    prevPage: null,
    exact: true,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },
  {
    key: 'AdminUserListPage',
    path: ADMIN_USER_LIST_PATH,
    pageTitle: 'My User List',
    component: AdminUserList,
    subRoutes: [],
    nextPage: { LOGIN_PAGE_PATH, REGISTRATION_PAGE_PATH },
    prevPage: null,
    exact: true,
    isPrivate: true,
    errorRoutes: ERROR_ROUTES,
  },

  {
    // SHOULD BE IN LAST INDEX
    key: 'NotfoundPage',
    path: ERROR_ROUTES,
    pageTitle: 'Notfound Page',
    component: NotFound,
    subRoutes: [],
    nextPage: null,
    prevPage: null,
    exact: true,
    isPrivate: false,
    errorRoutes: ERROR_ROUTES,
  },
];

export const COMMON_ROUTE_KEYS = [
  'FeedbackPage',
  'PostInvitePage',
  'UnpublishedMessagePage',
  'DeletedVideoMessagePage',
];

const ROUTES = [...PRIVATE_ROUTES, ...PUBLIC_ROUTES];

export default ROUTES;
