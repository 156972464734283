import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import ROUTES, { SPLASH_PAGE_PATH } from './appRoutes';

const Scroll = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return props.children;
};

Scroll.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const ScrollToTop = withRouter(Scroll);

const AppRoute = () => {
  return (
    <ScrollToTop>
      <Switch>
        {ROUTES.map((route) => (
          <Route
            strict={true}
            exact={route.exact}
            key={`${route.key}-${route.pageTitle}`}
            path={route.path}
            component={route.isPrivate ? PrivateRoute : PublicRoute}
          />
        ))}
        <Redirect exact={true} from="/" to={SPLASH_PAGE_PATH} />
      </Switch>
    </ScrollToTop>
  );
};

export default AppRoute;
