import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'reactstrap';
import style from './Loader.module.scss';
/**
 * Name: Loader
 * Desc: Render loader
 * @param {bool} pageLoader
 * @param {string} size
 */
const Loader = ({ pageLoader, size }) => {
  const loaderStyle = classnames({
    [style.loadersContainer]: pageLoader,
    [style.loaderWrapper]: !pageLoader,
  });

  const loaderSize = size ? { size } : {};

  return (
    <div className={loaderStyle}>
      <Spinner {...loaderSize} type="grow" color="light" />
    </div>
  );
};

Loader.defaultProps = {
  pageLoader: false,
  size: '',
};

Loader.propTypes = {
  pageLoader: PropTypes.bool,
  size: PropTypes.string,
};

export default Loader;
