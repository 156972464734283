import S3 from 'aws-sdk/clients/s3';
import { API_RESPONSE_STATUS } from 'utils/constants';
import { getBucketInfo } from 'utils/utils';
import Axios from '../axiosConfig/axios';

const handleResponse = ({ data }) => {
  if (data.status === API_RESPONSE_STATUS.SUCCESS && data?.data) {
    return { ...data, hasError: false };
  }
  return { ...data, hasError: true };
};

const fetchData = async (url, method = 'post', reqParams, config = {}) => {
  try {
    const response = await Axios[method](url, reqParams, config);
    return handleResponse(response);
  } catch (error) {
    throw new Error({ hasError: true, ...error });
  }
};

const waitFor = (s3, params) => {
  return new Promise((resolve, reject) => {
    s3.waitFor('objectExists', params, (err, data) => {
      if (err) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ ...err, isExist: false });
      } else {
        resolve({ ...data, isExist: true });
      }
    });
  }).catch((e) => {
    return { ...e, isExist: false };
  });
};

export const fetchFile = async (fileName, FileType = 'video') => {
  if (!fileName) {
    return null;
  }
  const {
    accessKeyId,
    secretAccessKey,
    name,
    path,
    profilePath,
    expires,
  } = getBucketInfo();

  const pathURL = FileType === 'profile' ? profilePath : path;

  const s3 = new S3({
    accessKeyId,
    secretAccessKey,
  });
  const params = {
    Bucket: name,
    Key: `${pathURL}${fileName}`,
  };
  const response = await waitFor(s3, params);

  if (response?.isExist) {
    const url = s3.getSignedUrl('getObject', {
      Bucket: name,
      Key: `${pathURL}${fileName}`,
      Expires: 60 * expires, // time in seconds: e.g. 60 * 5 = 30 mins
    });
    return url;
  }
  return null;
};

export const catchAxiosError = (error) => {
  return {
    hasError: true,
    data: {
      message: 'Could not process your request at this time. Please try again.',
    },
    ...error,
  };
};

export const sendData = async (...args) => {
  const data = await fetchData(...args).catch(catchAxiosError);
  return data;
};
