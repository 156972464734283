import closeIcon from 'images/close-gray.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'reactstrap';
import Button from 'shared/Button';
import Dialog from 'shared/Dialog';
import {
  FILE_UPLOAD_STATUS,
  TITLE_CHAR_LIMIT,
} from 'shared/Header/AddVideoLibrary/AddVideoLibraryConstants';
import Image from 'shared/Image';
import Inputs from 'shared/Inputs';
import Link from 'shared/Link';
import Text from 'shared/Text';
import { ERROR_PREFIX } from 'utils/constants';
import Style from './AddVideoLibraryModal.module.scss';
import messages from './messages';
import VideoUpload from './VideoUpload';
/**
 * Name:AddVideoLibraryModal
 * Desc: Render AddVideoLibraryModal
 * @param {object} addVideoModal
 * @param {function} onCloseAddVideoModal
 */

const AddVideoLibraryModal = ({
  exceedFileSize,
  isOpen,
  title,
  description,
  progressCount,
  fileName,
  fileSize,
  uploadStatus,
  onAddVideo,
  onFileDelete,
  onCloseModal,
  onFileChange,
  onHandleChange,
  errorMsg,
  handleValidation,
}) => {
  const buttonType = false;
  const primary = 'primary';
  const lightGray = 'lightGray';
  return (
    <Dialog
      hasOpen={isOpen}
      backdrop="static"
      hasHeader={false}
      className={Style.videoModalWrapper}
    >
      <div className={Style.modalHeader}>
        <Text
          className={Style.modalHeaderTitle}
          fontFamily="semiBold"
          fontSize="fsxl"
          text="Add a Video to Your Library"
        />
        <Button className={Style.closeIcon} onClick={onCloseModal}>
          <Image name="profile" src={closeIcon} />
        </Button>
      </div>
      <div className={Style.modalBody}>
        <Text
          className={Style.modalHeaderTitle}
          color="lightGrey"
          text="Add a video to your library by dragging and dropping the file below or browing your computer library. For faster and more convenient uploading of your recordings, we recommend you use our mobile "
        />
        <Link size="lg" color="skyBlue" to="/student" href="#" text="app." />
        <Text
          className={Style.videoTitle}
          fontFamily="medium"
          text="Title *"
          as="div"
        />
        <FormGroup className="mb-0">
          <Inputs
            type="text"
            labelSizeLg
            label="Provide a title for your video."
            letterCount={`${title.length}/${TITLE_CHAR_LIMIT}`}
            name="title"
            value={title}
            onChange={onHandleChange}
            onBlur={handleValidation}
            errorMessage={
              errorMsg ? `${ERROR_PREFIX} ${messages.errorMessage}` : ''
            }
            className={Style.videoInput}
          />
        </FormGroup>
        <Text
          className={Style.videoTitle}
          fontFamily="medium"
          text="Description"
          as="div"
        />
        <FormGroup className={Style.description}>
          <Inputs
            type="textarea"
            variant="lg"
            name="description"
            value={description}
            onChange={onHandleChange}
            label="Provide a description for your video."
            labelSizeLg
          />
        </FormGroup>
        <VideoUpload
          exceedFileSize={exceedFileSize}
          onFileChange={onFileChange}
          progressCount={progressCount}
          fileName={fileName}
          uploadStatus={uploadStatus}
          fileSize={fileSize}
          onFileDelete={onFileDelete}
          isFileUploaded={uploadStatus === FILE_UPLOAD_STATUS.COMPLETED}
        />
        <div className={Style.addVideoButton}>
          <Button
            variant={buttonType ? lightGray : primary}
            text="Add to My Videos"
            disabled={uploadStatus !== FILE_UPLOAD_STATUS.COMPLETED || !title}
            onClick={onAddVideo}
          />
        </div>
      </div>
    </Dialog>
  );
};

AddVideoLibraryModal.propTypes = {
  title: PropTypes.string,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  fileName: PropTypes.string,
  uploadStatus: PropTypes.string,
  progressCount: PropTypes.number,
  onFileChange: PropTypes.func,
  onAddVideo: PropTypes.func,
  fileSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFileDelete: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleValidation: PropTypes.func,
  exceedFileSize: PropTypes.bool,
};
export default AddVideoLibraryModal;
