import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import style from './Dialog.module.scss';

/**
 * Name: Dialog
 * Desc: Render Dialog box
 * @param {node} children
 * @param {string} title
 * @param {node} footer
 * @param {string} className
 * @param {func} onClose
 * @param {bool} hasOpen
 * @param {bool} backdrop
 * @param {bool} hasHeader
 * @param {object} rest
 */

const Dialog = ({
  children,
  title,
  footer,
  className,
  onClose = () => {},
  hasOpen = false,
  backdrop,
  hasHeader,
  ...rest
}) => {
  const [openDialog, setOpenDialog] = useState(hasOpen);

  useEffect(() => {
    setOpenDialog(hasOpen);
  }, [hasOpen]);

  const handleToggle = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <div className={style.dialogWrapper}>
      <Modal
        isOpen={openDialog}
        toggle={handleToggle}
        onClosed={onClose}
        className={className}
        backdrop={backdrop}
        centered={true}
        {...rest}
      >
        {hasHeader && <ModalHeader toggle={handleToggle}>{title}</ModalHeader>}
        <ModalBody>{children}</ModalBody>
        {!!footer && <ModalFooter>{footer}</ModalFooter>}
      </Modal>
    </div>
  );
};

Dialog.defaultProps = {
  backdrop: true,
  hasHeader: true,
  rest: {},
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  hasOpen: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  hasHeader: PropTypes.bool,
  rest: PropTypes.object,
};

export default Dialog;
