import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from 'shared/Icon'
import styles from './Link.module.scss'

/**
 * Name: Link
 * Desc: Render anchor tag
 * @param {string} text
 * @param {string} href
 * @param {string} icon
 * @param {string} variant
 * @param {string} size
 * @param {string} color
 * @param {bool} readOnly
 * @param {bool} textDecoration
 * @param {string} fontWeight
 * @param {func} onClick
 * @param {string} target
 * @param {string} dataTest,
 * @param {string} fontFamily
 */
const Link = props => {
  const {
    onClick,
    href,
    text,
    children,
    icon,
    size,
    color,
    readOnly,
    fontFamily,
    textDecoration,
    fontWeight,
    target,
    styling,
    className,
    variant,
    dataTest,
    cursor,
    onMouseEnter,
    addHostUrlToHref,
    ...rest
  } = props

  const events = {
    ...(onClick && { onClick }),
    ...(onMouseEnter && { onMouseEnter })
  }

  const linkClasses = classNames(className, {
    [styles[size]]: size,
    [styles.cursorPointer]: cursor === 'pointer',
    [styles.pointerEvent]: readOnly,
    [styles[fontFamily]]: fontFamily,
    [styles[color]]: color,
    [styles.textDecoration]: textDecoration,
  })
  return (
    <a
      href={href}
      target={target}
      data-test={dataTest}
      className={linkClasses}
      {...events}
      {...rest}
    >
      {text || children}
      {icon && <Icon variant={icon} />}
    </a>
  )
}

// Default Props
Link.defaultProps = {
  size: 'md',
  variant: 'primary',
  readOnly: false
}

// Props Validations
Link.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  cursor: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  styling: PropTypes.string,
  target: PropTypes.string,
  textDecoration: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dataTest: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  readOnly: PropTypes.bool,
  addHostUrlToHref: PropTypes.bool,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  onMouseEnter: PropTypes.func
}

export default Link