import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Heading.module.scss';
/**
 * Name: Heading
 * Desc: Render heading
 * @param {string} type
 * @param {string,element} text
 * @param {string} color
 * @param {string,bool} lineThrough
 * @param {string,bool} fontWeightNormal
 * @param {string} className
 */
const Heading = ({
  text,
  type,
  color,
  lineThrough,
  fontWeightNormal,
  className,
}) => {
  const classes = classNames(
    styles[type],
    { 
      [className]: !!className,
      [styles[color]]: !!color,
      [styles.fontWeightNormal]: !!fontWeightNormal,
      [styles.lineThrough]: !!lineThrough,
    },
  );

  const Component = type;
  return <Component className={classes}>{text}</Component>;
};

// Props Validation
Heading.propTypes = {
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  lineThrough: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  fontWeightNormal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Heading;
