import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
/**
 * Name : ScrollBar
 * Desc : Render ScrollBar
 * @param {node} children
 * @param {string} className
 * @param {bool} autoHide
 */

const ScrollBar = ({ children, className, autoHide, refScroll }) => {
  const classes = classNames('scroll-bar', className);
  const rest = refScroll ? { ref: refScroll } : {};
  return (
    <SimpleBar className={classes} autoHide={autoHide} {...rest}>
      {children}
    </SimpleBar>
  );
};

ScrollBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  autoHide: PropTypes.bool,
  refScroll: PropTypes.object,
};

ScrollBar.defaultProps = {
  autoHide: false,
};
export default ScrollBar;
