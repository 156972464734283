import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { videoUploadSuccess } from 'redux/actions/videoUploadActions';
import { MY_VIDEOS_PAGE_PATH } from 'routes/appRoutes';
import { sendData } from 'services';
import { APIS } from 'utils/constants';
import {
  formatBytes,
  getVideoDimension,
  getVideoDuration,
  isMobile,
} from 'utils/utils';
import {
  FILE_UPLOAD_STATUS,
  TITLE_CHAR_LIMIT,
} from './AddVideoLibraryConstants';

export const uploadFile = async (file, config) => {
  const fileName = file?.name;
  const getVideoDimensionData = await getVideoDimension(file);
  // Create an object of formData
  const formData = new FormData();
  formData.append('video', file, fileName);
  formData.append('uploadedFrom', 'web');
  formData.append(
    'orientation',
    getVideoDimensionData.width > getVideoDimensionData.height
      ? 'landscape'
      : 'portrait',
  );
  formData.append('videoHeight', getVideoDimensionData.height);
  formData.append('videoWidth', getVideoDimensionData.width);

  const response = await sendData(APIS.STUDENT_UPLOAD_FILE, 'post', formData, {
    ...config,
    header: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const useAddVideo = (
  userInfo,
  history,
  toggleModal,
  isOpenAddVideoModal,
) => {
  const dispatch = useDispatch();
  const { user_id: userId = '' } = userInfo;
  const uploadResponse = useRef({});

  const initValues = {
    fileName: '',
    fileSize: '',
    uploadStatus: '', // FILE_UPLOAD_STATUS
    duration: '',
  };
  const [fileInfo, setFileInfo] = useState(initValues);
  const [title, setTitle] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const [description, setDescription] = useState('');
  const [progressCount, setProgressCount] = useState(0);
  const [exceedFileSize, setExceedFileSize] = useState(false);

  useEffect(() => {
    uploadResponse.current = {};
    setFileInfo(initValues);
    if (!isOpenAddVideoModal) {
      setTitle('');
      setDescription('');
      setExceedFileSize(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddVideoModal]);

  const resetFileData = () => {
    uploadResponse.current = {};
    setFileInfo(initValues);
  };

  const handleAddVideo = async () => {
    const {
      videoId,
      fileName,
      thumbnailImage = '',
      size,
      duration,
      url,
    } = uploadResponse.current;
    setTitle(''); // Disabled Button once click on it
    const { hasError } = await sendData(APIS.ADD_TO_MY_VIDEO, 'post', {
      ownerUserId: userId,
      title,
      description,
      duration: parseFloat(duration),
      fileName,
      thumbnailImage,
      videoStatusId: videoId,
      status: 'draft',
      uploadedFrom: isMobile() ? 'mobileBrowser' : 'web',
      size: parseFloat(size),
      url,
    });

    if (!hasError) {
      resetFileData();
      toggleModal();

      dispatch(videoUploadSuccess());
      history.push(MY_VIDEOS_PAGE_PATH);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    const newValue = value.replace(/^\s+/g, '');
    if (name === 'title') {
      if (newValue.length <= TITLE_CHAR_LIMIT) {
        newValue === '' ? setErrorMsg(true) : setErrorMsg(false);
        setTitle(newValue);
      }
    }
    if (name === 'description') {
      setDescription(newValue);
    }
  };

  const handleValidation = ({ target: { value } }) => {
    value === '' ? setErrorMsg(true) : setErrorMsg(false);
    setTitle(value);
  };

  const handleFileChange = async ({
    target: {
      files: [file],
    },
  }) => {
    setExceedFileSize(false);
    if (file.size > 2147483648) {
      setExceedFileSize(true);
      return '';
    }
    const duration = await getVideoDuration(file);
    const newValues = {
      ...fileInfo,
      duration,
      fileName: file.name,
      fileSize: formatBytes(file.size),
    };
    setFileInfo(newValues);
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setProgressCount(percentCompleted);
      },
    };
    const { hasError, data } = await uploadFile(file, config);
    uploadResponse.current = data;
    if (!hasError) {
      setFileInfo({
        ...newValues,
        uploadStatus: FILE_UPLOAD_STATUS.COMPLETED,
      });
    }
  };

  const handleDeleteFile = async () => {
    const { fileName, filePath } = uploadResponse.current;
    const { hasError } = await sendData(APIS.DELETE_MY_VIDEO, 'delete', {
      data: { fileName, filePath },
    });
    if (!hasError) {
      resetFileData();
    }
  };

  return {
    ...fileInfo,
    exceedFileSize,
    title,
    errorMsg,
    description,
    progressCount,
    handleAddVideo,
    handleFileChange,
    handleDeleteFile,
    handleChange,
    handleValidation,
    setTitle,
  };
};
