import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LOGIN_PAGE_PATH } from './appRoutes';

const AuthRoute = ({
  component: Component,
  isAuthenticated,
  layout: Layout,
  isPrivate,
  session,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return (
            <Suspense fallback={<></>}>
              <Component
                nextPage={rest.nextPage}
                prevPage={rest.prevPage}
                pageTitle={rest.pageTitle}
                isAuthenticated={isAuthenticated}
                session={session}
                {...props}
              />
            </Suspense>
          );
        }

        return (
          <Redirect
            to={{
              pathname: LOGIN_PAGE_PATH,
              state: { from: props.location.pathname },
            }}
          />
        );
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.object,
  socket: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  layout: PropTypes.func,
  location: PropTypes.object,
  isPrivate: PropTypes.bool,
  session: PropTypes.object,
};

export default AuthRoute;
