import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import style from './TextInput.module.scss';

/**
 * Name : TextInput
 * Desc : Render TextInput
 * @param {string} type
 * @param {string} value
 * @param {string} name
 * @param {any} id
 * @param {string} placeholder
 * @param {bool} disabled
 * @param {func} onChange
 * @param {func} onBlur
 * @param {string} label
 * @param {string} errorMessage
 * @param {string} letterCount
 * @param {string} className
 * @param {string,bool} labelSizeLg
 */

const TextInput = ({
  label,
  errorMessage,
  letterCount,
  labelSizeLg,
  className,
  inputRef,
  ...rest
}) => {
  const labelClasses = classNames({ [style.labelSizeLg]: labelSizeLg });
  const inputClasses = classNames(style.formControl, {
    [style.error]: errorMessage,
    [className]: className,
  });
  const reference = inputRef ? { ref: inputRef } : {};

  return (
    <>
      {label && (
        <label className={labelClasses} htmlFor={label}>
          {label}
        </label>
      )}
      <div className={style.inputWrapper}>
        <input
          aria-label={rest.name}
          className={inputClasses}
          {...rest}
          {...reference}
        />
      </div>
      {(errorMessage || letterCount) && (
        <div className={style.inputTextValidation}>
          {errorMessage && (
            <span className={style.errorMessage}>{errorMessage}</span>
          )}
          {letterCount && (
            <span className={style.letterCount}>{letterCount}</span>
          )}
        </div>
      )}
    </>
  );
};

TextInput.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  letterCount: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
    PropTypes.object,
  ]),
  errorMessage: PropTypes.string,
  labelSizeLg: PropTypes.bool,
  inputRef: PropTypes.object,
};

export default TextInput;
