import home from 'assets/images/home.svg';
import people from 'assets/images/people.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'shared/Button';
import Image from 'shared/Image';
import AddVideoLibrary from '../AddVideoLibrary';
import HeaderDropDown from '../HeaderDropDown';
import HeaderNotificationBell from '../HeaderNotificationBell';
import style from './HeaderRightPanel.module.scss';

/**
 * Name: HeaderRightPanel
 * Desc: Render HeaderRightPanel of
 * @param {bool} addVideo
 */
const HeaderRightPanel = ({ addVideo, userInfo, history }) => {
  const [isOpenAddVideoModal, setIsOpenAddVideoModal] = useState(false);

  const handleAddVideoModal = () => {
    setIsOpenAddVideoModal(!isOpenAddVideoModal);
  };
  return (
    <div className={style.headerRightPanel}>
      <div className={style.navIcons}>
        {!addVideo && (
          <Link to="/" title="Home">
            <Image name="Home" src={home} width="31px" height="24px" />
          </Link>
        )}
        <Link to="/users/all" title="People">
          <Image name="people" src={people} width="31px" height="24px" />
        </Link>

        <HeaderNotificationBell userInfo={userInfo} history={history} />
      </div>
      <HeaderDropDown
        userInfo={userInfo}
        onClickAddVideoModal={handleAddVideoModal}
      />

      {addVideo && (
        <Button
          variant="primary"
          className={style.videoButton}
          onClick={handleAddVideoModal}
        >
          + Video
        </Button>
      )}
      <AddVideoLibrary
        isOpenAddVideoModal={isOpenAddVideoModal}
        toggleModal={handleAddVideoModal}
        userInfo={userInfo}
        history={history}
      />
    </div>
  );
};
HeaderRightPanel.propTypes = {
  addVideo: PropTypes.bool,
  history: PropTypes.object,
  userInfo: PropTypes.object,
};
export default HeaderRightPanel;
