import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import AppRoute from 'routes';

const App = () => {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <AppRoute />
      </LastLocationProvider>
    </BrowserRouter>
  );
};

export default App;
