import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from 'shared/Footer';
import Header from 'shared/Header';
import SideNavBar from 'shared/SideNavBar';
import styles from './Layouts.module.scss';
/**
 * Name: Layouts
 * Desc: this is container on header footer and page
 * @param {node} children
 * @param {string} pageStyle
 */
const Layout = ({
  children,
  style = '',
  showHeader = true,
  showFooter = true,
  isAuthenticated = false,
  showSideBar = false,
  isSplashPage,
  history,
  ...rest
}) => {
  const layoutStyle = classnames('main-page', style, {
    [styles.sideNavBar]: showSideBar,
  });
  return (
    <>
      {showHeader && (
        <Header
          isAuthenticated={isAuthenticated}
          isSplashPage={isSplashPage}
          history={history}
        />
      )}
      <main className={layoutStyle}>
        {showSideBar && <SideNavBar {...rest} history={history} />}
        {children}
      </main>
      {showFooter && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.string,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isSplashPage: PropTypes.bool,
  history: PropTypes.object,
  showSideBar: PropTypes.bool,
};

export default Layout;
