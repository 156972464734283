import { combineReducers } from 'redux';
import addCollection from './addCollectionReducer';
import auth from './authReducer';
import sidebarData from './sidebarReducer';
import videoUpload from './videoUploadReducer';

const rootReducer = combineReducers({
  auth,
  videoUpload,
  addCollection,
  sidebarData,
});

export default rootReducer;
