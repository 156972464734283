import { collectionAddSuccess } from 'reduxApp/constants';
import initialState from './initialState';

const { UPDATE_LAST_COLLECTION } = collectionAddSuccess;

const addCollectionReducer = (state = initialState.collectionAdd, action) => {
  switch (action.type) {
    case UPDATE_LAST_COLLECTION:
      return {
        ...state,
        lastCollectionAdded: action.payload,
      };

    default:
      return state;
  }
};

export default addCollectionReducer;
