import { sidebarDataConstants } from 'reduxApp/constants';
import initialState from './initialState';

const { UPDATE_VIDEO_COUNT, UPDATE_COLLECTION_COUNT } = sidebarDataConstants;

const sidebarDataReducer = (state = initialState.sidebarData, action) => {
  switch (action.type) {
    case UPDATE_VIDEO_COUNT:
      return {
        ...state,
        videos: action.payload,
      };
    case UPDATE_COLLECTION_COUNT:
      return {
        ...state,
        collections: action.payload,
      };
    default:
      return state;
  }
};

export default sidebarDataReducer;
