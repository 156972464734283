import { videoUploadConstants } from 'reduxApp/constants';
import initialState from './initialState';

const { UPDATE_LAST_VIDEO } = videoUploadConstants;

const videoUploadReducer = (state = initialState.videoUpload, action) => {
  switch (action.type) {
    case UPDATE_LAST_VIDEO:
      return {
        ...state,
        lastVideoAdded: action.payload,
      };

    default:
      return state;
  }
};

export default videoUploadReducer;
