import blueCheckMark from 'images/blue-checkmark.svg';
import blueClose from 'images/blue-close.svg';
import uploadingImg from 'images/uploading-animation.gif';
import fileUploaded from 'images/video-file-uploaded.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { Progress } from 'reactstrap';
import Button from 'shared/Button';
import FileDropZone from 'shared/FileDropZone';
import Image from 'shared/Image';
import Text from 'shared/Text';
import Style from './VideoUpload.module.scss';

const VideoUpload = ({
  progressCount,
  fileName,
  uploadStatus,
  fileSize,
  onFileChange,
  onFileDelete,
  isFileUploaded,
  exceedFileSize,
}) => {
  return (
    <>
      <FileDropZone onChange={onFileChange} busy={!!fileName || fileSize} />
      {exceedFileSize && (
        <Text
          fontSize="sm"
          color="dangerColor"
          text="Maximum file size that you can upload is 2 GB."
          className={Style.errorMessage}
        />
      )}
      <div className={Style.uploadWrapper}>
        {!!fileSize && !!fileName && (
          <>
            <div className={Style.fileStatus}>
              <div className={Style.fileDetail}>
                <div className={Style.fileDummyImage}>
                  <Image name="profile" src={fileUploaded} />
                </div>
                <Text fontSize="sm" color="lightGrey" text={fileName} />
              </div>
              <div className={Style.uploadingStatus}>
                {isFileUploaded ? (
                  <>
                    <Image name="profile" src={blueCheckMark} />
                    <Button className={Style.iconOnly} onClick={onFileDelete}>
                      <Image name="profile" src={blueClose} />
                    </Button>
                  </>
                ) : (
                  <Image name="profile" src={uploadingImg} />
                )}
              </div>
            </div>
            <div className={Style.progressBarStatus}>
              <div className={Style.progressBarText}>
                <Text
                  fontSize="sm"
                  color="lightBlack"
                  text={`${progressCount}% of ${fileSize} uploaded`}
                />
                <Text fontSize="sm" color="lightBlack" text={uploadStatus} />
              </div>
              <div className={Style.progressBar}>
                <Progress variant="success" value={progressCount} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

VideoUpload.propTypes = {
  fileName: PropTypes.string,
  uploadStatus: PropTypes.string,
  progressCount: PropTypes.number,
  onFileChange: PropTypes.func,
  fileSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFileDelete: PropTypes.func,
  isFileUploaded: PropTypes.bool,
  exceedFileSize: PropTypes.bool,
};
export default VideoUpload;
