import closeIcon from 'images/close-gray.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'reactstrap';
import Button from 'shared/Button';
import Dialog from 'shared/Dialog';
import Image from 'shared/Image';
import Inputs from 'shared/Inputs';
import Text from 'shared/Text';
import { ERROR_PREFIX } from 'utils/constants';
import style from './AddCollectionModel.module.scss';
import messages from './messages';

const AddCollectionModel = ({
  isOpen,
  onCloseModel,
  onHandleChange,
  title,
  onHandleValidation,
  description,
  errorMsg,
  onAddCollection,
}) => {
  return (
    <Dialog
      hasOpen={isOpen}
      backdrop="static"
      hasHeader={false}
      className={style.collectionModalWrapper}
    >
      <div className={style.modalHeader}>
        <Text
          className={style.modalHeaderTitle}
          fontFamily="semiBold"
          fontSize="fsxl"
          text="Create a New Collection"
        />
        <Button className={style.closeIcon} onClick={onCloseModel}>
          <Image name="profile" src={closeIcon} />
        </Button>
      </div>
      <div className={style.modalBody}>
        <FormGroup className="mb-0">
          <Inputs
            type="text"
            labelSizeLg
            label="Name"
            name="title"
            value={title}
            onChange={onHandleChange}
            onBlur={onHandleValidation}
            errorMessage={
              errorMsg ? `${ERROR_PREFIX} ${messages.errorMessage}` : ''
            }
            className={style.collectionNameInput}
          />
        </FormGroup>
        <FormGroup className={style.description}>
          <Inputs
            type="textarea"
            className={style.descriptionInput}
            name="description"
            value={description}
            onChange={onHandleChange}
            label="Description"
            labelSizeLg
          />
        </FormGroup>
        <div className={style.addCollectionButton}>
          <Button
            variant="outline-primary"
            text="Cancel"
            onClick={onCloseModel}
            className={style.cancelBtn}
          />
          <Button
            variant="primary"
            text="Create Collection"
            disabled={!title}
            onClick={onAddCollection}
            className={style.createCollectionBtn}
          />
        </div>
      </div>
    </Dialog>
  );
};
AddCollectionModel.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModel: PropTypes.func,
  onHandleChange: PropTypes.func,
  title: PropTypes.string,
  onHandleValidation: PropTypes.func,
  description: PropTypes.string,
  errorMsg: PropTypes.bool,
  onAddCollection: PropTypes.func,
};
export default AddCollectionModel;
