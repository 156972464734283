import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarDataConstants } from 'reduxApp/constants';
import { sendData } from 'services';
import { APIS } from 'utils/constants';

const { UPDATE_VIDEO_COUNT, UPDATE_COLLECTION_COUNT } = sidebarDataConstants;

const useUpdateCount = (vidReqParams, colReqParams) => {
  const { lastVideoAdded } = useSelector((state) => state.videoUpload);
  const { lastCollectionAdded } = useSelector((state) => state.addCollection);
  const dispatch = useDispatch();

  const getCount = async ({ url, params, type }) => {
    const { x_total_count: recordCount } = await sendData(url, 'post', params);

    dispatch({
      type,
      payload: recordCount,
    });
  };

  const fetchCount = useCallback((type) => {
    const apiParams = {
      url: APIS.GET_MY_VIDEOS,
      params: vidReqParams,
      type: UPDATE_VIDEO_COUNT,
    };
    if (type === 'Collection') {
      apiParams.url = APIS.GET_ALL_COLLECTION;
      apiParams.params = colReqParams;
      apiParams.type = UPDATE_COLLECTION_COUNT;
    }
    getCount(apiParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCount('Collection');
  }, [fetchCount, lastCollectionAdded]);

  useEffect(() => {
    fetchCount('Video');
  }, [fetchCount, lastVideoAdded]);
};

export default useUpdateCount;
