export default {
  auth: {
    loading: false,
    loggedIn: false,
    userInfo: {},
    errors: {},
  },
  notification: {
    messages: '',
    type: '',
  },
  videoUpload: {
    lastVideoAdded: '',
  },
  collectionAdd: {
    lastCollectionAdded: '',
  },
  sidebarData: {
    videos: 0,
    collections: 0,
  },
};
