import iconsAllCopy from 'images/icons-all-copy.svg';
import iconsAll from 'images/icons-all.svg';
import iconsNavCollectionsCopy from 'images/icons-nav-collections-blue.svg';
import iconsNavCollections from 'images/icons-nav-collections.svg';
import iconsNavCreatedByMeCopy from 'images/icons-nav-created-by-me-blue.svg';
import iconsNavCreatedByMe from 'images/icons-nav-created-by-me.svg';
import iconsNavPublicCopy from 'images/icons-nav-public-blue.svg';
import iconsNavPublic from 'images/icons-nav-public.svg';
import iconsNavSavedByMeCopy from 'images/icons-nav-saved-by-me-blue.svg';
import iconsNavSavedByMe from 'images/icons-nav-saved-by-me.svg';
import iconsNavSharedCopy from 'images/icons-nav-shared-blue.svg';
import iconsNavShared from 'images/icons-nav-shared.svg';
import iconsNavVideoCopy from 'images/icons-nav-video-blue.svg';
import iconsNavVideo from 'images/icons-nav-video.svg';
import {
  ALL_PAGE_PATH,
  HOME_PAGE_PATH,
  MY_COLLECTION_PAGE_PATH,
  MY_REVIEWS_PAGE_PATH,
  MY_SAVED_PAGE_PATH,
  MY_VIDEOS_PAGE_PATH,
  SHARED_WITH_ME_PAGE_PATH,
} from 'routes/appRoutes';

export const workMenuItems = [
  {
    id: 1,
    to: ALL_PAGE_PATH,
    name: 'All',
    icon: iconsAll,
    hoverIcon: iconsAllCopy,
  },
  {
    id: 2,
    to: MY_VIDEOS_PAGE_PATH,
    name: 'My videos',
    icon: iconsNavVideo,
    isAddVideo: true,
    hoverIcon: iconsNavVideoCopy,
  },
  {
    id: 3,
    to: MY_COLLECTION_PAGE_PATH,
    name: 'My collections',
    icon: iconsNavCollections,
    isAddCollection: true,
    hoverIcon: iconsNavCollectionsCopy,
  },
];
export const reviewMenuItems = [
  {
    id: 4,
    to: HOME_PAGE_PATH,
    name: 'Public',
    icon: iconsNavPublic,
    hoverIcon: iconsNavPublicCopy,
  },
  {
    id: 5,
    to: SHARED_WITH_ME_PAGE_PATH,
    name: 'Shared with me',
    icon: iconsNavShared,
    hoverIcon: iconsNavSharedCopy,
  },
  {
    id: 6,
    to: MY_REVIEWS_PAGE_PATH,
    name: 'Created by me',
    icon: iconsNavCreatedByMe,
    hoverIcon: iconsNavCreatedByMeCopy,
  },
  {
    id: 7,
    to: MY_SAVED_PAGE_PATH,
    name: 'Saved by me',
    icon: iconsNavSavedByMe,
    hoverIcon: iconsNavSavedByMeCopy,
  },
];
