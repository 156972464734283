import { authConstants } from 'reduxApp/constants';
import { sendData } from 'services';
import { APIS } from 'utils/constants';

export const loginSuccess = (user) => {
  return {
    type: authConstants.LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailure = (error) => ({
  type: authConstants.LOGIN_FAILURE,
  payload: error,
});

export const logoutSuccess = () => ({
  type: authConstants.LOGOUT_SUCCESS,
  payload: {},
});

export const login = (accessDetails) => async (dispatch) => {
  try {
    const data = await sendData(APIS.LOGIN, 'post', accessDetails);
    return data;
  } catch (error) {
    dispatch(loginFailure(error));
    throw new Error({ ...error, hasError: true });
  }
};

export const socialLogin = (accessDetails) => async (dispatch) => {
  try {
    const data = await sendData(APIS.LOGIN, 'post', accessDetails);
    return data;
  } catch (error) {
    dispatch(loginFailure(error));
    throw new Error({ ...error, hasError: true });
  }
};

export const logout = (requestParam) => async (dispatch) => {
  try {
    const data = await sendData(APIS.LOGOUT, 'post', requestParam);
    dispatch(logoutSuccess(data));
  } catch (error) {
    dispatch(logoutSuccess(error));
    throw new Error({ ...error, hasError: true });
  }
};
