import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addCollectionAction } from 'redux/actions/addCollectionAction';
import { MY_COLLECTION_PAGE_PATH } from 'routes/appRoutes';
import { sendData } from 'services';
import { APIS } from 'utils/constants';

const TITLE_CHAR_LIMIT = 28;

export const useAddCollection = (
  isOpenAddCollectionModel,
  toggleModal,
  userInfo,
  history,
  autoRefresh,
) => {
  const { user_id: userId = '' } = userInfo;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState();
  const [errorMsg, setErrorMsg] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isOpenAddCollectionModel) {
      setTitle('');
      setDescription('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddCollectionModel]);

  const handleOnChange = ({ target: { name, value } }) => {
    const newValue = value.replace(/^\s+/g, '');
    if (name === 'title') {
      if (newValue.length <= TITLE_CHAR_LIMIT) {
        newValue === '' ? setErrorMsg(true) : setErrorMsg(false);
        setTitle(newValue);
      }
    }
    if (name === 'description') {
      setDescription(newValue);
    }
  };

  const handleValidation = ({ target: { value } }) => {
    value === '' ? setErrorMsg(true) : setErrorMsg(false);
    setTitle(value);
  };

  const handleAddCollection = async () => {
    const { hasError } = await sendData(APIS.ADD_COLLECTION, 'post', {
      ownerUserId: userId,
      title,
      description,
      status: 'active',
    });

    if (!hasError) {
      toggleModal();
      dispatch(addCollectionAction());
      if (autoRefresh) {
        history.push(MY_COLLECTION_PAGE_PATH);
      }
    }
  };

  return {
    toggleModal,
    handleOnChange,
    title,
    handleValidation,
    description,
    errorMsg,
    handleAddCollection,
  };
};

export const useAddToCollection = ({
  isOpenAddToCollectionModel,
  isOpenAddCollectionModel,
  toggleAddToCollectionModel,
  userInfo,
  videoId,
}) => {
  const dispatch = useDispatch();
  const [collectionsData, setCollectionData] = useState([]);
  const [collectionIds, setCollectionIds] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const { user_id: userId = '' } = userInfo;

  const fetchCollection = async () => {
    const { hasError, data } = await sendData(APIS.GET_ALL_COLLECTION, 'post', {
      reqParam: {
        fetchFor: '',
        ownerUserId: userId,
        sort: { created_at: -1 },
        perRecords: 15,
        currentUserId: '',
        videoId,
      },
    });
    setCollectionData(data);
    if (hasError) {
      toggleAddToCollectionModel();
    }
  };
  useEffect(() => {
    if (!isOpenAddCollectionModel && isOpenAddToCollectionModel) {
      fetchCollection(userId);
    }
    if (!isOpenAddToCollectionModel) {
      setCollectionIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddToCollectionModel, isOpenAddCollectionModel]);

  const handleOnChangeCollection = ({ target: { value, name, checked } }) => {
    if (name === 'searchTitle') {
      setSearchTitle(value);
    } else if (checked) {
      if (!collectionIds.find((ids) => ids === value)) {
        setCollectionIds([...collectionIds, value]);
      }
    } else if (!checked && collectionIds.find((ids) => ids === value)) {
      const index = collectionIds.indexOf(value);
      if (index > -1) {
        collectionIds.splice(index, 1);
      }
      setCollectionIds([...collectionIds]);
    }
  };

  let collectionFilterData = [...collectionsData];
  if (searchTitle && !!searchTitle.length) {
    collectionFilterData = collectionsData.filter(
      (collection) =>
        collection.title.toLowerCase().indexOf(searchTitle.toLowerCase()) >= 0,
    );
  }

  const handleAddToCollection = async () => {
    setLoader(true);
    const { hasError } = await sendData(APIS.ADD_VIDEO_TO_COLLECTION, 'post', {
      videoIds: videoId,
      collectionId: collectionIds,
    });
    if (!hasError) {
      setLoader(false);
      setCollectionIds([]);
      toggleAddToCollectionModel();
      dispatch(addCollectionAction());
    }
  };

  return {
    collectionsData: collectionFilterData,
    handleOnChangeCollection,
    collectionIds,
    searchTitle,
    handleAddToCollection,
    loader,
  };
};

export const useAddVideoToCollection = ({
  isOpenAddVideoToCollectionModel,
  toggleAddVideoToCollectionModel,
  userInfo,
  collectionId,
  autoRefresh,
  onUpdateRecord,
}) => {
  const dispatch = useDispatch();
  const { user_id: userId, token } = userInfo;
  const [videos, setVideos] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [selectedVideosIds, setSelectedVideosIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const fetchVideosData = async () => {
    const { hasError, data } = await sendData(APIS.GET_MY_VIDEOS, 'post', {
      reqParam: {
        ownerUserId: userId,
        sort: { created_at: -1 },
        perRecords: 15,
        token,
        collectionId,
      },
    });

    if (!hasError) {
      setVideos(data);
    }
  };
  useEffect(() => {
    fetchVideosData();
    if (!isOpenAddVideoToCollectionModel) {
      setSelectedVideosIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddVideoToCollectionModel]);

  const handleOnChangeVideos = ({ target: { value, name, checked } }) => {
    if (name === 'searchTitle') {
      setSearchTitle(value);
    } else if (checked) {
      if (!selectedVideosIds.find((ids) => ids === value)) {
        setSelectedVideosIds([...selectedVideosIds, value]);
      }
    } else if (!checked && selectedVideosIds.find((ids) => ids === value)) {
      const index = selectedVideosIds.indexOf(value);
      if (index > -1) {
        selectedVideosIds.splice(index, 1);
      }
      setSelectedVideosIds([...selectedVideosIds]);
    }
  };
  let videosFilterData = [...videos];
  if (searchTitle && !!searchTitle.length) {
    videosFilterData = videos.filter(
      (video) =>
        video.title.toLowerCase().indexOf(searchTitle.toLowerCase()) >= 0,
    );
  }
  const handleAddVideoToCollection = async () => {
    setLoader(true);
    const { hasError } = await sendData(APIS.ADD_VIDEO_TO_COLLECTION, 'post', {
      videoIds: selectedVideosIds,
      collectionId,
    });
    if (!hasError) {
      setLoader(false);
      setSelectedVideosIds([]);
      toggleAddVideoToCollectionModel();
      if (autoRefresh) {
        dispatch(addCollectionAction());
      } else {
        onUpdateRecord();
      }
    }
  };
  return {
    videos: videosFilterData,
    handleOnChangeVideos,
    searchTitleVideo: searchTitle,
    selectedVideosIds,
    handleAddVideoToCollection,
    loader,
  };
};
