import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import Layout from 'shared/Layouts';
import { PUBLIC_ROUTES } from './appRoutes';
import NoAuthRoute from './NoAuthRoute';

const PublicRoute = (props) => {
  const { loggedIn } = useSelector((state) => state.auth);
  return (
    <Switch>
      {PUBLIC_ROUTES.map((route) => {
        return (
          <NoAuthRoute
            key={route.pageTitle}
            id={route.key}
            isAuthenticated={loggedIn}
            layout={Layout}
            history={props.history}
            showHeader={false}
            {...route}
          />
        );
      })}
    </Switch>
  );
};

export default PublicRoute;
