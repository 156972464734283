import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import style from './CheckBox.module.scss';

/**
 * Name: CheckBox
 * Desc: Render CheckBox
 * @param {string} id
 * @param {string or object} label
 */

const CheckBox = ({ labelSelectNone, className, space, ...rest }) => {
  const labelSelectNoneVal = labelSelectNone ? 1 : 0;
  const checkboxClasses = classNames(style.checkBox, {
    [style.m0]: space === 'm0',
  });
  return (
    <div className={checkboxClasses}>
      <input type="checkbox" id={rest.id} {...rest} className={className} />
      <label
        data-label-select-none={labelSelectNoneVal}
        data-test="checkbox"
        data-input="checkbox"
        htmlFor={rest.id}
      >
        {rest.label}
      </label>
    </div>
  );
};

// Props validation
CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  space: PropTypes.string,
  className: PropTypes.string,
  labelSelectNone: PropTypes.bool,
};

export default CheckBox;
