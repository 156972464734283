import logo from 'assets/images/skoodio-logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  LOGIN_PAGE_PATH,
  REGISTRATION_PAGE_PATH,
  SPLASH_PAGE_PATH,
} from 'routes/appRoutes';
import Image from 'shared/Image';
import Text from 'shared/Text';
import style from './Header.module.scss';
import HeaderRightPanel from './HeaderRightPanel';

/**
 * Name: Header
 * Desc: Render header of app
 */
const Header = ({ isAuthenticated, history = {} }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const uniqueReviewId = window.location.pathname.split('/').pop();
  const { _id: userId } = userInfo;
  return (
    <header>
      <div className={style.headerWrapper}>
        <div className={style.navLinks}>
          <Link
            to={SPLASH_PAGE_PATH}
            className={style.leftHeaderIcon}
            title="Home"
          >
            <Image name="profile" src={logo} />

            {/* <Text
              fontSize="xxlg"
              text="Home"
              color="white"
              fontFamily="semiBold"
            /> */}
          </Link>
          {/* TODO */}
          {/* <Text
              fontSize="xxlg"
              text="|"
              color="darkGrayButton"
              fontFamily="semiBold"
              className={style.linkSeparator}
            />
            <Link
              to={HOME_PAGE_PATH}
              className={style.leftHeaderIcon}
              title="Home"
            >
              <Text
                fontSize="xxlg"
                text="Community"
                color="darkGrayButton"
                fontFamily="semiBold"
              />
            </Link> */}
        </div>

        <div className={style.splashHeader}>
          <Link to={SPLASH_PAGE_PATH}>
            {/* // <Image name="profile" src={logo} /> */}
          </Link>
        </div>
        {!isAuthenticated && (
          <div className={style.navLinks}>
            <Link to={REGISTRATION_PAGE_PATH} title="Sign Up" className="mr-4">
              <Text
                fontSize="xxlg"
                text="Sign Up"
                color="white"
                fontFamily="semiBold"
              />
            </Link>
            <Link
              to={
                !userId && uniqueReviewId
                  ? `${LOGIN_PAGE_PATH}?id=${uniqueReviewId}`
                  : LOGIN_PAGE_PATH
              }
              title="Log In"
            >
              <Text
                fontSize="xxlg"
                text="Log In"
                color="white"
                fontFamily="semiBold"
              />
            </Link>
          </div>
        )}
        {isAuthenticated && (
          <HeaderRightPanel
            userInfo={userInfo}
            addVideo={isAuthenticated}
            history={history}
          />
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  isSplashPage: PropTypes.bool,
  history: PropTypes.object,
};
export default Header;
